exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-character-background-tsx": () => import("./../../../src/pages/character/background.tsx" /* webpackChunkName: "component---src-pages-character-background-tsx" */),
  "component---src-pages-character-classes-tsx": () => import("./../../../src/pages/character/classes.tsx" /* webpackChunkName: "component---src-pages-character-classes-tsx" */),
  "component---src-pages-character-companion-tsx": () => import("./../../../src/pages/character/companion.tsx" /* webpackChunkName: "component---src-pages-character-companion-tsx" */),
  "component---src-pages-character-general-feats-tsx": () => import("./../../../src/pages/character/general-feats.tsx" /* webpackChunkName: "component---src-pages-character-general-feats-tsx" */),
  "component---src-pages-character-how-to-tsx": () => import("./../../../src/pages/character/how-to.tsx" /* webpackChunkName: "component---src-pages-character-how-to-tsx" */),
  "component---src-pages-character-species-tsx": () => import("./../../../src/pages/character/species.tsx" /* webpackChunkName: "component---src-pages-character-species-tsx" */),
  "component---src-pages-concoctions-tsx": () => import("./../../../src/pages/concoctions.tsx" /* webpackChunkName: "component---src-pages-concoctions-tsx" */),
  "component---src-pages-conversion-spells-tsx": () => import("./../../../src/pages/conversion/spells.tsx" /* webpackChunkName: "component---src-pages-conversion-spells-tsx" */),
  "component---src-pages-creatures-index-tsx": () => import("./../../../src/pages/creatures/index.tsx" /* webpackChunkName: "component---src-pages-creatures-index-tsx" */),
  "component---src-pages-creatures-rules-tsx": () => import("./../../../src/pages/creatures/rules.tsx" /* webpackChunkName: "component---src-pages-creatures-rules-tsx" */),
  "component---src-pages-creatures-statistics-tsx": () => import("./../../../src/pages/creatures/statistics.tsx" /* webpackChunkName: "component---src-pages-creatures-statistics-tsx" */),
  "component---src-pages-equipment-alchemical-creations-tsx": () => import("./../../../src/pages/equipment/alchemical-creations.tsx" /* webpackChunkName: "component---src-pages-equipment-alchemical-creations-tsx" */),
  "component---src-pages-equipment-armor-tsx": () => import("./../../../src/pages/equipment/armor.tsx" /* webpackChunkName: "component---src-pages-equipment-armor-tsx" */),
  "component---src-pages-equipment-gear-tsx": () => import("./../../../src/pages/equipment/gear.tsx" /* webpackChunkName: "component---src-pages-equipment-gear-tsx" */),
  "component---src-pages-equipment-index-tsx": () => import("./../../../src/pages/equipment/index.tsx" /* webpackChunkName: "component---src-pages-equipment-index-tsx" */),
  "component---src-pages-equipment-magic-items-tsx": () => import("./../../../src/pages/equipment/magic-items.tsx" /* webpackChunkName: "component---src-pages-equipment-magic-items-tsx" */),
  "component---src-pages-equipment-technological-wonders-tsx": () => import("./../../../src/pages/equipment/technological-wonders.tsx" /* webpackChunkName: "component---src-pages-equipment-technological-wonders-tsx" */),
  "component---src-pages-equipment-weapons-tsx": () => import("./../../../src/pages/equipment/weapons.tsx" /* webpackChunkName: "component---src-pages-equipment-weapons-tsx" */),
  "component---src-pages-feats-tsx": () => import("./../../../src/pages/feats.tsx" /* webpackChunkName: "component---src-pages-feats-tsx" */),
  "component---src-pages-fighting-styles-tsx": () => import("./../../../src/pages/fighting-styles.tsx" /* webpackChunkName: "component---src-pages-fighting-styles-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-license-tsx": () => import("./../../../src/pages/license.tsx" /* webpackChunkName: "component---src-pages-license-tsx" */),
  "component---src-pages-maneuvers-tsx": () => import("./../../../src/pages/maneuvers.tsx" /* webpackChunkName: "component---src-pages-maneuvers-tsx" */),
  "component---src-pages-rules-abilities-tsx": () => import("./../../../src/pages/rules/abilities.tsx" /* webpackChunkName: "component---src-pages-rules-abilities-tsx" */),
  "component---src-pages-rules-advantage-disadvantage-tsx": () => import("./../../../src/pages/rules/advantage-disadvantage.tsx" /* webpackChunkName: "component---src-pages-rules-advantage-disadvantage-tsx" */),
  "component---src-pages-rules-adventuring-tsx": () => import("./../../../src/pages/rules/adventuring.tsx" /* webpackChunkName: "component---src-pages-rules-adventuring-tsx" */),
  "component---src-pages-rules-aptitude-bonus-tsx": () => import("./../../../src/pages/rules/aptitude-bonus.tsx" /* webpackChunkName: "component---src-pages-rules-aptitude-bonus-tsx" */),
  "component---src-pages-rules-combat-tsx": () => import("./../../../src/pages/rules/combat.tsx" /* webpackChunkName: "component---src-pages-rules-combat-tsx" */),
  "component---src-pages-rules-concoctions-maneuvers-and-spells-tsx": () => import("./../../../src/pages/rules/concoctions-maneuvers-and-spells.tsx" /* webpackChunkName: "component---src-pages-rules-concoctions-maneuvers-and-spells-tsx" */),
  "component---src-pages-rules-conditions-tsx": () => import("./../../../src/pages/rules/conditions.tsx" /* webpackChunkName: "component---src-pages-rules-conditions-tsx" */),
  "component---src-pages-rules-inspiration-tsx": () => import("./../../../src/pages/rules/inspiration.tsx" /* webpackChunkName: "component---src-pages-rules-inspiration-tsx" */),
  "component---src-pages-rules-languages-tsx": () => import("./../../../src/pages/rules/languages.tsx" /* webpackChunkName: "component---src-pages-rules-languages-tsx" */),
  "component---src-pages-rules-quick-reference-tsx": () => import("./../../../src/pages/rules/quick-reference.tsx" /* webpackChunkName: "component---src-pages-rules-quick-reference-tsx" */),
  "component---src-pages-rules-rolls-tsx": () => import("./../../../src/pages/rules/rolls.tsx" /* webpackChunkName: "component---src-pages-rules-rolls-tsx" */),
  "component---src-pages-rules-saving-throws-tsx": () => import("./../../../src/pages/rules/saving-throws.tsx" /* webpackChunkName: "component---src-pages-rules-saving-throws-tsx" */),
  "component---src-pages-rules-skills-tsx": () => import("./../../../src/pages/rules/skills.tsx" /* webpackChunkName: "component---src-pages-rules-skills-tsx" */),
  "component---src-pages-spells-tsx": () => import("./../../../src/pages/spells.tsx" /* webpackChunkName: "component---src-pages-spells-tsx" */),
  "component---src-pages-themes-distribution-tsx": () => import("./../../../src/pages/themes/distribution.tsx" /* webpackChunkName: "component---src-pages-themes-distribution-tsx" */),
  "component---src-pages-themes-index-tsx": () => import("./../../../src/pages/themes/index.tsx" /* webpackChunkName: "component---src-pages-themes-index-tsx" */),
  "component---src-templates-changelog-tsx": () => import("./../../../src/templates/changelog.tsx" /* webpackChunkName: "component---src-templates-changelog-tsx" */),
  "component---src-templates-class-tsx": () => import("./../../../src/templates/class.tsx" /* webpackChunkName: "component---src-templates-class-tsx" */),
  "component---src-templates-concoction-tsx": () => import("./../../../src/templates/concoction.tsx" /* webpackChunkName: "component---src-templates-concoction-tsx" */),
  "component---src-templates-creature-tsx": () => import("./../../../src/templates/creature.tsx" /* webpackChunkName: "component---src-templates-creature-tsx" */),
  "component---src-templates-feat-tsx": () => import("./../../../src/templates/feat.tsx" /* webpackChunkName: "component---src-templates-feat-tsx" */),
  "component---src-templates-fighting-style-tsx": () => import("./../../../src/templates/fighting-style.tsx" /* webpackChunkName: "component---src-templates-fighting-style-tsx" */),
  "component---src-templates-maneuver-tsx": () => import("./../../../src/templates/maneuver.tsx" /* webpackChunkName: "component---src-templates-maneuver-tsx" */),
  "component---src-templates-species-tsx": () => import("./../../../src/templates/species.tsx" /* webpackChunkName: "component---src-templates-species-tsx" */),
  "component---src-templates-spell-tsx": () => import("./../../../src/templates/spell.tsx" /* webpackChunkName: "component---src-templates-spell-tsx" */),
  "component---src-templates-theme-tsx": () => import("./../../../src/templates/theme.tsx" /* webpackChunkName: "component---src-templates-theme-tsx" */)
}

