import React from 'react';
import { SettingsMenu } from './SettingsMenu';
import { Navigation } from './Navigation';

export const Header = () => (
  <div className="shadow-md print:hidden">
    <div className="mx-auto flex w-[75rem] max-w-full items-start justify-between gap-2">
      <Navigation />
      <SettingsMenu />
    </div>
  </div>
);
