import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getFromLS } from '../utils/local-storage';

export type GlobalContextProviderType = {
  appearance: string;
  metricSystem: boolean;
  rollStrategy: string;
  setAppearance: (value: string) => void;
  setMetricSystem: (value: boolean) => void;
  setRollStrategy: (value: string) => void;
  setTwoDTen: (value: boolean) => void;
  twoDTen: boolean;
};

export const GlobalContext = createContext({} as GlobalContextProviderType);

export const GlobalContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [appearance, setAppearance] = useState(
    getFromLS('kryxRpgAppearance', 'auto') as string,
  );
  const [metricSystem, setMetricSystem] = useState(
    getFromLS('kryxRpgMetricSystem', true) as boolean,
  );
  const [twoDTen, setTwoDTen] = useState(
    getFromLS('kryxRpgTwoDTen', true) as boolean,
  );
  const [rollStrategy, setRollStrategy] = useState(
    getFromLS('kryxRpgRollStrategy', 'attacker-rolls-for-attacks') as string,
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('kryxRpgAppearance', appearance);
    }
  }, [appearance]);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('kryxRpgMetricSystem', metricSystem.toString());
    }
  }, [metricSystem]);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('kryxRpgTwoDTen', twoDTen.toString());
    }
  }, [twoDTen]);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('kryxRpgRollStrategy', rollStrategy);
    }
  }, [rollStrategy]);

  const state: GlobalContextProviderType = useMemo(
    () => ({
      appearance,
      metricSystem,
      rollStrategy,
      setAppearance,
      setMetricSystem,
      setRollStrategy,
      setTwoDTen,
      twoDTen,
    }),
    [
      appearance,
      metricSystem,
      rollStrategy,
      setAppearance,
      setMetricSystem,
      setRollStrategy,
      setTwoDTen,
      twoDTen,
    ],
  );

  return (
    <GlobalContext.Provider value={state}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalContext = (): GlobalContextProviderType =>
  useContext(GlobalContext);
