import React, { type MutableRefObject } from 'react';
import { IconArrowUp } from '@tabler/icons-react';
import { Button } from './Button';

export const ScrollTop = ({
  refTopOfPage,
}: {
  refTopOfPage: MutableRefObject<HTMLInputElement | null>;
}) => (
  <Button
    aria-label="Scroll to the top of the page"
    className="fixed bottom-9 right-3 md:right-4 lg:right-6"
    emphasis="medium"
    onClick={() => {
      refTopOfPage.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }}
    size="icon"
  >
    <IconArrowUp />
  </Button>
);
