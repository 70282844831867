import React from 'react';
import { Link } from 'gatsby';
import { DropdownMenuSeparator } from '../DropdownMenu';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuListItem,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '../NavigationMenu';

export const Navigation = () => (
  <div className="flex flex-wrap items-center">
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuLink asChild className={navigationMenuTriggerStyle()}>
            <Link to="/">Kryx RPG</Link>
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Character</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="flex flex-col gap-2">
              <NavigationMenuListItem to="/character/how-to">
                How to make a character
              </NavigationMenuListItem>
              <DropdownMenuSeparator />
              <NavigationMenuListItem to="/character/species">
                Species
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/character/background">
                Background
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/character/classes">
                Classes
              </NavigationMenuListItem>
              <DropdownMenuSeparator />
              <NavigationMenuListItem
                rel="noopener noreferrer"
                target="_blank"
                to="https://docs.google.com/spreadsheets/d/1_m-cphXFxOK7xzMRtfa9r9OLDLdq1R7stv0-tLK8qJ8/edit?usp=sharing"
              >
                Character sheet
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/character/companion">
                Companion
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/character/general-feats">
                General feats
              </NavigationMenuListItem>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Creatures</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="flex flex-col gap-2">
              <NavigationMenuListItem to="/creatures">
                Creatures
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/creatures/rules">
                Rules
              </NavigationMenuListItem>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Equipment</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="flex flex-col gap-2">
              <NavigationMenuListItem to="/equipment/alchemical-creations">
                Alchemical creations
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/equipment/armor">
                Armor
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/equipment">
                Equipment
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/equipment/gear">
                Gear
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/equipment/magic-items">
                Magic items
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/equipment/technological-wonders">
                Technological wonders
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/equipment/weapons">
                Weapons
              </NavigationMenuListItem>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Rules</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="flex flex-col gap-2">
              <NavigationMenuListItem to="/rules/abilities">
                Abilities
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/rules/advantage-disadvantage">
                Advantage and disadvantage
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/rules/adventuring">
                Adventuring
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/rules/aptitude-bonus">
                Aptitude Bonus
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/rules/combat">
                Combat
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/rules/concoctions-maneuvers-and-spells">
                Concoctions, maneuvers, and spells
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/rules/conditions">
                Conditions
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/rules/inspiration">
                Inspiration
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/rules/languages">
                Languages
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/rules/quick-reference">
                Quick reference
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/rules/rolls">
                Rolls
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/rules/saving-throws">
                Saving throws
              </NavigationMenuListItem>
              <NavigationMenuListItem to="/rules/skills">
                Skills
              </NavigationMenuListItem>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuLink asChild className={navigationMenuTriggerStyle()}>
            <Link to="/themes">Themes</Link>
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  </div>
);
