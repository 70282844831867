import React from 'react';
import { clsx } from 'clsx';
import { useForm } from 'react-hook-form';
import { IconDotsVertical } from '@tabler/icons-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useMediaQuery } from 'usehooks-ts';
import { useGlobalContext } from '../GlobalContext';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../Form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../Select';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '../DropdownMenu';
import { Button } from '../Button';
import { Switch } from '../Switch';

const formSchema = z.object({
  appearance: z.string(),
  metricSystem: z.boolean(),
  rollStrategy: z.string(),
  twoDTen: z.boolean(),
});

type FormSchemaType = z.infer<typeof formSchema>;

export const SettingsMenu = () => {
  const {
    appearance,
    metricSystem,
    rollStrategy,
    setAppearance,
    setMetricSystem,
    setRollStrategy,
    setTwoDTen,
    twoDTen,
  } = useGlobalContext();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
  });
  const { control } = form;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button aria-label="Settings menu" emphasis="low">
          <IconDotsVertical />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={clsx('w-80', {
          dark:
            appearance === 'dark' || (appearance === 'auto' && prefersDarkMode),
        })}
      >
        <Form {...form}>
          <form>
            <DropdownMenuLabel>Settings</DropdownMenuLabel>
            <DropdownMenuGroup>
              <DropdownMenuItem>
                <FormField
                  control={control}
                  name="appearance"
                  render={() => (
                    <FormItem className="flex w-full items-center justify-between gap-4">
                      <FormLabel>Appearance</FormLabel>
                      <Select
                        defaultValue={appearance}
                        onValueChange={(value) => {
                          setAppearance(value);
                        }}
                      >
                        <FormControl>
                          <SelectTrigger className="w-auto">
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="auto">Set by system</SelectItem>
                          <SelectItem value="dark">Dark</SelectItem>
                          <SelectItem value="light">Light</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </DropdownMenuItem>
              <DropdownMenuItem>
                <FormField
                  control={control}
                  name="metricSystem"
                  render={() => (
                    <FormItem className="flex w-full items-center justify-between gap-4">
                      <FormLabel>Metric system</FormLabel>
                      <FormControl>
                        <Switch
                          checked={metricSystem}
                          onCheckedChange={() => setMetricSystem(!metricSystem)}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </DropdownMenuItem>
              <DropdownMenuItem>
                <FormField
                  control={control}
                  name="twoDTen"
                  render={() => (
                    <FormItem className="flex w-full items-center justify-between gap-4">
                      <FormLabel>2d10</FormLabel>
                      <FormControl>
                        <Switch
                          checked={twoDTen}
                          onCheckedChange={() => setTwoDTen(!twoDTen)}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </DropdownMenuItem>
              <DropdownMenuItem>
                <FormField
                  control={control}
                  name="rollStrategy"
                  render={() => (
                    <FormItem className="flex w-full items-center justify-between gap-4">
                      <FormLabel>Roll strategy</FormLabel>
                      <Select
                        defaultValue={rollStrategy}
                        onValueChange={(value) => {
                          setRollStrategy(value);
                        }}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="attacker-rolls-for-attacks">
                            Attacker rolls for attacks, defender rolls for
                            saving throws (5e)
                          </SelectItem>
                          <SelectItem value="attacker-always-rolls">
                            Attacker always rolls (4e)
                          </SelectItem>
                          <SelectItem value="both-roll">
                            Both the attacker and defender roll
                          </SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </form>
        </Form>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
