import React, { forwardRef, type ButtonHTMLAttributes } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { clsx } from 'clsx';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    defaultVariants: {
      emphasis: 'high',
      size: 'md',
    },
    variants: {
      emphasis: {
        high: 'bg-primary text-primary-foreground hover:bg-primary/90',
        low: 'hover:bg-accent hover:text-accent-foreground',
        medium:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
      },
      size: {
        icon: 'h-11 w-11',
        md: 'h-11 px-4 py-2',
      },
    },
  },
);

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ asChild, className, emphasis, size, ...rest }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        ref={ref}
        className={clsx(buttonVariants({ className, emphasis, size }))}
        {...rest}
      />
    );
  },
);
Button.displayName = 'Button';
