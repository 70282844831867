const getLS = (value: string, defaultValue: string | boolean) => {
  if (value !== null) {
    return value;
  }
  return defaultValue;
};

export const getFromLS = (key: string, defaultValue: string | boolean) => {
  if (typeof window === 'undefined') {
    return defaultValue;
  }
  const valueFromLS = localStorage.getItem(key);
  if (valueFromLS) {
    return getLS(valueFromLS, defaultValue);
  }
  return defaultValue;
};
