import React, { type ReactNode } from 'react';
import { clsx } from 'clsx';
import { Link as GatsbyLink } from 'gatsby';

export type LinkProps = {
  children: ReactNode;
  className?: string;
  colored?: boolean;
  href?: string;
  to?: string;
  underline?: boolean;
};

export const Link = ({
  children,
  className,
  colored = true,
  href,
  to,
  underline = true,
  ...rest
}: LinkProps) => {
  if (to) {
    return (
      <GatsbyLink
        className={clsx({ 'text-primary': colored, underline }, className)}
        to={to}
        {...rest}
      >
        {/* TODO: fix */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {children}
      </GatsbyLink>
    );
  }
  if (href && href.startsWith('#')) {
    return (
      <a
        className={clsx({ 'text-primary': colored, underline }, className)}
        href={href}
        {...rest}
      >
        {children}
      </a>
    );
  }
  return (
    <a
      className={clsx({ 'text-primary': colored, underline }, className)}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
      {...rest}
    >
      {children}
    </a>
  );
};
