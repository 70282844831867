import React, { useRef } from 'react';
import { clsx } from 'clsx';
import { useMediaQuery } from 'usehooks-ts';
import { Header } from './Header';
import { useGlobalContext } from './GlobalContext';
import { Footer } from './Footer';
import { ScrollTop } from './ScrollTop';
import '../styles/global.css';

export const Layout = ({
  children,
  wide,
}: {
  children: React.ReactNode;
  wide?: boolean;
}) => {
  const { appearance } = useGlobalContext();
  const refTopOfPage = useRef<HTMLInputElement | null>(null);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  return (
    <div
      className={clsx(
        'flex min-h-full min-w-80 flex-col bg-background text-foreground',
        {
          dark:
            appearance === 'dark' || (appearance === 'auto' && prefersDarkMode),
        },
      )}
    >
      <div ref={refTopOfPage} />
      <Header />
      <main
        className={clsx(
          'mx-auto min-h-full max-w-full grow p-3 md:p-4 lg:p-6',
          {
            'w-[75rem]': !wide,
            'w-full': wide,
          },
        )}
      >
        {children}
        <ScrollTop refTopOfPage={refTopOfPage} />
      </main>
      <Footer />
    </div>
  );
};
