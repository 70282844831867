import React from 'react';
import { GlobalContextProvider } from './src/components/GlobalContext';
import { Layout } from './src/components/Layout';

export const onServiceWorkerUpdateReady = () => {
  if (typeof window !== 'undefined' && window) {
    window.location.reload(true);
  }
};

export const wrapPageElement = ({ element }) => (
  <GlobalContextProvider>
    <Layout>{element}</Layout>
  </GlobalContextProvider>
);
