import React from 'react';
import { Link } from './Link';

export const Footer = () => (
  <div className="mx-auto mb-2 mt-12 flex w-[75rem] max-w-full items-start justify-center gap-4 print:hidden">
    <Link to="/changelog/2024">Changelog</Link>
    <Link href="https://discord.gg/jP8byZF">Join us on Discord</Link>
    <Link href="https://github.com/mlenser/kryx-rpg-issues/issues">
      Report an issue
    </Link>
    <Link to="/license">License</Link>
  </div>
);
